<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">预付款管理（第三方）</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px;">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="searchForm.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" round @click="addInstitutionAdvanceChargeDataOpen('')">新增机构</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="机构名称" align="left" prop="compName" show-overflow-tooltip min-width="160" />
              <el-table-column label="预付总额" align="left" prop="payMoneyNum" show-overflow-tooltip min-width="100" />
              <el-table-column label="预警金额" align="left" prop="payWarnNum" show-overflow-tooltip width="140" />
              <el-table-column label="暂停审核" align="left" prop="payState" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.payState" active-color="#13ce66" inactive-color="#ff4949"
                    active-value="10" inactive-value="20" @change="((scope.row)=changeCayState(scope.row))">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addInstitutionAdvanceChargeDataOpen(scope.row)">编辑机构</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addInstitutionAdvanceChargeDataDelete(scope.row)">删除</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addAdvanceChargeDataOpen(scope.row)">增加预付款(查看预付记录)</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增&编辑机构预付款 -->
    <el-dialog :title="addInstitutionAdvanceChargeData.title + '预付款'"
      :visible.sync="addInstitutionAdvanceChargeData.dialogVisible" width="500px"
      :before-close="addInstitutionAdvanceChargeDataClose">
      <el-form :model="addInstitutionAdvanceChargeData.formData_1" :rules="addInstitutionAdvanceChargeData.rules_1"
        ref="formData_1" size="small" label-width="80px">
        <el-form-item label="机构名称" prop="compId">
          <el-select size="small" v-model="addInstitutionAdvanceChargeData.formData_1.compId" remote
            :remote-method="getCompanyList_1" filterable clearable placeholder="请至少输入两个字搜索"
            :disabled="this.addInstitutionAdvanceChargeData.title == '编辑'">
            <el-option v-for="item in addInstitutionAdvanceChargeData.CompanyList" :key="item.compId"
              :label="item.compName" :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预警额" prop="payWarnNum">
          <el-input-number class="input_1" v-model="addInstitutionAdvanceChargeData.formData_1.payWarnNum" :min="0"
            :precision="0"></el-input-number>
        </el-form-item>
        <el-form-item label="暂停审核" prop="payState" v-if="addInstitutionAdvanceChargeData.title == '新增'">
          <el-switch v-model="addInstitutionAdvanceChargeData.formData_1.payState" active-color="#13ce66"
            inactive-color="#ff4949" active-value="10" inactive-value="20">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addInstitutionAdvanceChargeDataOpenOk" class="bgc-bv">确定</el-button>
      </span>
    </el-dialog>
    <!-- 追加预付款 -->
    <el-dialog title="追加预付款" :visible.sync="addAdvanceChargeData.dialogVisible" width="800px"
      @close="addAdvanceChargeDataClose">
      <el-form :model="addAdvanceChargeData.formData_2" :rules="addAdvanceChargeData.rules_2" ref="formData_2"
        size="small" label-width="100px">
        <el-form-item label="机构名称" prop="compId">
          <el-select size="small" v-model="addAdvanceChargeData.formData_2.compId" remote
            :remote-method="getCompanyList_2" filterable clearable placeholder="请至少输入两个字搜索" disabled>
            <el-option v-for="item in addAdvanceChargeData.CompanyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预付款余额">
          <span class="text_2">￥</span><span class="text_1">{{ addAdvanceChargeData.formData_2.payMoneyNum }}</span>
        </el-form-item>
        <el-form-item label="追加预付款" prop="payDetailMoneyNum">
          <el-input-number class="input_1" v-model="addAdvanceChargeData.formData_2.payDetailMoneyNum"
            :precision="0"></el-input-number>
        </el-form-item>
      </el-form>
      <div class="sh_1">预付款记录：</div>
      <el-table ref="multipleTable" :data="addAdvanceChargeData.tableData" size="small" tooltip-effect="dark"
        style="width: 100%" :header-cell-style="tableHeader" stripe>
        <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
        <el-table-column label="金额" align="center" prop="payDetailMoneyNum" show-overflow-tooltip min-width="100" />
        <el-table-column label="操作人" align="center" prop="adminName" show-overflow-tooltip min-width="100" />
        <el-table-column label="操作时间" align="center" prop="createTime" show-overflow-tooltip min-width="160" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAdvanceChargeDataOpenOk" class="bgc-bv">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "operate_advancePaymentManagementThreeSquare",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        compId: "", // 机构名称
      },
      // 机构名称 - 下拉数据
      CompanyList: [],
      // 新增&编辑机构预付款 - 弹框数据
      addInstitutionAdvanceChargeData: {
        dialogVisible: false, // 弹框状态
        title: "新增", // 弹框标题
        CompanyList: [], // 机构
        // 表单数据
        formData_1: {
          compId: "", // 机构id
          payId: "", // 购买id
          payWarnNum: 0, // 预警金额
          payState: "10", // 启用状态
        },
        // 表单数据 - 校验
        rules_1: {
          compId: [
            { required: true, message: '请选择机构', trigger: 'change' }
          ],
          payWarnNum: [
            { required: true, message: '请输入预警金额', trigger: 'blur' }
          ],
        }
      },
      // 追加预付款 - 弹框数据
      addAdvanceChargeData: {
        dialogVisible: false, //弹框状态
        // 表单数据
        formData_2: {
          compId: "", // 机构id
          payId: "", // 购买id
          payDetailMoneyNum: "", // 追加预付款
        },
        // 表单数据 - 校验
        rules_2: {
          compId: [
            { required: true, message: '请选择机构', trigger: 'change' }
          ],
          payDetailMoneyNum: [
            { required: true, message: '请输入追加预付款金额', trigger: 'blur' }
          ],
        },
        CompanyList: [], // 机构
        tableData: [], // 预付款记录
      },
    };
  },
  computed: {},
  created() { },
  methods: {
    // 获取 - 机构(两个字查询) - 检索条件用
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 机构(两个字查询) - 新增&编辑预付款 - 弹框用
    getCompanyList_1(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.addInstitutionAdvanceChargeData.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.addInstitutionAdvanceChargeData.CompanyList = [];
      }
    },
    // 获取 - 机构(两个字查询) - 追加预付款 - 弹框用
    getCompanyList_2(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.addAdvanceChargeData.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.addAdvanceChargeData.CompanyList = [];
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      this.doFetch({
        url: "/biz/course/pay/queryCoursePayList",
        params,
        pageNum,
      });
    },
    // 更改 - 暂停审核
    changeCayState(row) {
      this.$post("/biz/course/pay/modify", {
        compId: row.compId,
        payId: row.payId,
        payState: row.payState,
        payWarnNum: row.payWarnNum,
      }).then(res => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.getData(this.pageNum);
      });
    },
    // 新增&编辑机构预付款 - 打开弹框
    addInstitutionAdvanceChargeDataOpen(row) {
      if (row) {
        this.addInstitutionAdvanceChargeData.title = "编辑";
        this.addInstitutionAdvanceChargeData.formData_1 = { ...this.addInstitutionAdvanceChargeData.formData_1, ...row };
        this.getCompanyList_1(row.compName);
      }
      this.addInstitutionAdvanceChargeData.dialogVisible = true;
    },
    // 新增&编辑机构预付款 - 关闭弹框
    addInstitutionAdvanceChargeDataClose() {
      this.addInstitutionAdvanceChargeData = this.$options.data().addInstitutionAdvanceChargeData;
      this.$nextTick(() => {
        this.$refs['formData_1'].resetFields();
      })
    },
    // 新增&编辑机构预付款 - 打开弹框 - 确定
    addInstitutionAdvanceChargeDataOpenOk() {
      this.$refs['formData_1'].validate((valid) => {
        if (valid) {
          let url = "/biz/course/pay/insert";
          if (this.addInstitutionAdvanceChargeData.title == "编辑") {
            url = "/biz/course/pay/modify";
          }
          this.$post(url, this.addInstitutionAdvanceChargeData.formData_1).then(
            (res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getData(this.pageNum);
              this.addInstitutionAdvanceChargeDataClose();
            }
          );
        }
      });
    },
    // 删除 - 机构预付款
    addInstitutionAdvanceChargeDataDelete(row) {
      this.$post("/biz/course/pay/delete", {
        payId: row.payId
      }).then(res => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.getData(this.pageNum);
      }
      );
    },
    // 追加预付款 - 打开弹框
    addAdvanceChargeDataOpen(row) {
      this.addAdvanceChargeData.formData_2 = { ...this.addAdvanceChargeData.formData_2, ...row }
      this.getCompanyList_2(row.compName);
      this.addAdvanceChargeDataOpenGetRecord();
      this.addAdvanceChargeData.dialogVisible = true;
    },
    // 追加预付款 - 打开弹框 - 获取预付款记录
    addAdvanceChargeDataOpenGetRecord() {
      this.$post("/biz/course/pay/detail/queryCoursePayDetailList", {
        payId: this.addAdvanceChargeData.formData_2.payId
      }).then(res => {
        this.addAdvanceChargeData.tableData = res.data;
      });
    },
    // 追加预付款 - 关闭弹框
    addAdvanceChargeDataClose() {
      this.addAdvanceChargeData = this.$options.data().addAdvanceChargeData;
      this.$nextTick(() => {
        this.$refs['formData_2'].resetFields();
      })
    },
    // 追加预付款 - 打开弹框 - 确定
    addAdvanceChargeDataOpenOk() {
      if (this.addAdvanceChargeData.formData_2.payDetailMoneyNum == 0) {
        this.$message({
          type: "warning",
          message: "追加预付款不能为0",
        });
        return
      }
      this.$refs['formData_2'].validate((valid) => {
        if (valid) {
          this.$post("/biz/course/pay/detail/insert", this.addAdvanceChargeData.formData_2).then(
            (res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getData(this.pageNum);
              this.addAdvanceChargeDataClose();
            }
          );
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.input_1 {
  width: 200px;
}

.text_1 {
  font-size: 22px;
  font-weight: 600;
  color: red;
}

.text_2 {
  font-size: 14px;
}

.sh_1 {
  padding-left: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>